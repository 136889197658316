import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const SessionDetailsBackground = ({ id, children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "sessiondetailsbackground.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <>
    <BackgroundImage
      Tag="section"
      id={id}
      fluid={imageData}
      backgroundColor={`var(--main-color)`}
      preserveStackingContext={true}
    >
      {children}      
    </BackgroundImage>
    </>
  )
}

export default SessionDetailsBackground